<template lang="html" src="./pageOrder.template.vue"></template>

<style lang="scss" src="./pageOrder.scss"></style>

<script>
import {
  ApiErrorParser,
  BeelseQuote,
  BtnMenu,
  CancelOrder,
  DeliveryTrackingV2,
  DownloadSupplierPartOriginalFile,
  DownloadSupplierOrderPartFile,
  PythonStatus,
  Steppers,
  SupplierOrderStepper,
} from '@cloudmanufacturingtechnologies/portal-components';

import SerialNumberCSGParametersSelector from '../../components/serialNumberCSGParametersSelector/SerialNumberCSGParametersSelector';

const i18nData = require('./pageOrder.i18n');

export default {
  name: 'PageOrder',
  components: {
    BeelseQuote,
    BtnMenu,
    CancelOrder,
    DeliveryTrackingV2,
    PythonStatus,
    SerialNumberCSGParametersSelector,
    Steppers,
    SupplierOrderStepper
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      /**
       * Misc.
       */
      downloadChoices: {},
      /**
       * Order (Industrial)
       */
      currentPart: null,
      dialogDeleteSerialNumberFile: false,
      dialogGenerateItemSerialNumberFile: false,
      dialogGenerateOrderSerialNumberFiles: false,
      expirationDate: false,
      expirationDays: 2,
      loadingAPI: false,
      methodsList: ['card', 'manual', 'sepa', 'transfer'],
      newPaymentMethod: null,
      order: null,
      loadingPdf: false,
      pdfData: null,
      orderCSGStatus: 'IDLE',
      partsDownloadName: {},
      partsFileDataURL: {},
      partsLoading: {},
      partsSerialNumbers: {},
      purchaseOrder: null,
      quote: null,
      stepOrder: 1,
      tooltipsCSG: {},
      wizardItemsOrder: [],
      cancelPartSelected: {},
      dialogRemovePartsFromOrder: {},
      loadingRemovePartsFromOrder: false,
      cancelDispatchCustomLineSelected: {},
      dialogRemoveDispatchCustomLine: {},
      cancelOrderCustomLineSelected: {},
      dialogRemoveOrderCustomLine: false,
      loadingRemoveCustomLine: false,
      paymentIntent: null,
      dialogSupplierPayment: {},
      invoiceNumber: null,
      /**
       * Dispatch (Supplier Bill)
       */
      deleteDispatchSupplierInvoice: null,
      deletionInProgress: false,
      deliveryTracking: null,
      deliveryTrackingComplete: false,
      deliveryTrackingErrorMessage: null,
      deliveryTrackingFormMode: null,
      deliveryTrackingIdsURL: null,
      deliveryTrackingInline: false,
      dialogForValidateNextStep2: false,
      dialogForValidateNextStep4: false,
      dialogManufacturingValidation: false,
      dispatchSupplier: null,
      dispatchSupplierList: [],
      modalDeletion: false,
      numberOfPackage: null,
      numberOfPackageValidate: false,
      partsFileMetaData: {},
      validationStatus: false,
      wizardItemsDispatch: [],
      /**
       * Order PDF = Bon de commande
       */
      suppliersOrderPDFData: {},
      suppliersOrderPDFLoading: {},
      /**
       * Delivery order PDF = Bon de livraison
       */
      suppliersDeliveryOrderPDFData: {},
      suppliersDeliveryOrderPDFLoading: {},
      /**
       * Invoice PDF = Facture fournisseur
       */
      suppliersInvoicePDFData: {},
      suppliersInvoicePDFLoading: {},
      dispatchesDataReady: false,
    };
  },
  created() {
    this.wizardItemsOrder = [
      this.$t('PAYMENT'),
      this.$t('INPROGRESS'),
      this.$t('DELIVERED'),
    ];
    this.wizardItemsDispatch = [
      this.$t('SCHEDULED'),
      this.$t('MANUFACTURING'),
      this.$t('MANUFACTURED'),
      this.$t('DELIVERY'),
      this.$t('DELIVERED'),
    ];
    this.getOrder();
    this.tooltipsCSG = {
      'IDLE': this.$t('StatusIdleTooltip'),
      'IN_PROGRESS': this.$t('StatusInProgressTooltip'),
      'VALID': this.$t('StatusValidTooltip'),
      'ERROR': this.$t('StatusErrorTooltip'),
      'INVALID': this.$t('StatusErrorTooltip'),
    };
  },
  methods: {
    updateItemDownloadChoices(item) {
      if(item.part?._id) {
        this.downloadChoices[item.part._id] = [
          {
            text: '.3MF',
            value: '3mf'
          },
          { 
            text: '.STL',
            value: 'stl'
          }
        ];

        if(item.part && item.part.originalFile && !this.partsSerialNumbers[item.part._id]) {

          this.downloadChoices[item.part._id].splice(0,0,{ 
            value: this.part.originalFile.extension,
            text: `.${this.part.originalFile.extension.toUpperCase()}`
          });
        
        }
      }
    },
    async getSupplierDataForEachDispatch() {
      for (const dispatch of this.order.quote.dispatch) {
        if (dispatch.supplier !== 'MANUAL') {
          this.dispatchSupplierList.push(
            await this.$apiInstance
              .getSupplier(dispatch.supplier)
              .then((data) => {
                return data;
              })
              .catch((err) => ApiErrorParser.parse(err))
          );
        } else {
          this.dispatchSupplierList.push(this.$t('ManualSupplier'));
        }
      }
    },
    /**
     * GET PART FILE
     */
    getPartFile(supplierUUID, partUUID, extension = null) {

      extension = extension || 'stl';

      let splitName = this.partsDownloadName[partUUID].split('.');
              
      splitName.pop();
      splitName.join('.');
      splitName += '.' + extension;
      this.partsDownloadName[partUUID] = splitName;

      const button = this.$refs[`downloadFileButton${partUUID}`][0];
      this.$set(this.partsLoading, partUUID, true);

      if(!this.partsFileDataURL[partUUID] || !this.partsFileDataURL[partUUID][extension]) {

        let promise, req = null;

        if(['step', 'obj', 'iges'].includes(extension)) {
          [promise, req] = DownloadSupplierPartOriginalFile.downloadSupplierPartOriginalFile(
            this.$apiInstance,
            supplierUUID,
            partUUID
          );
        } else {
          [promise, req] = DownloadSupplierOrderPartFile.downloadSupplierOrderPartFile(
            this.$apiInstance,
            supplierUUID,
            this.$route.params.orderUUID,
            partUUID,
            this.partsSerialNumbers[partUUID] ? this.partsSerialNumbers[partUUID].serialNumber.file.extension : this.partsFileMetaData[partUUID].extension.toLowerCase(),
            extension
          );
        }

        promise.then((response) => {
          this.arrayBuffer = response.buffer;
          const byteArray = new Uint8Array(this.arrayBuffer);
          const blob = new Blob([byteArray], {
            type: 'application/octet-stream',
          });
          /**
           * Using $set to make it reactive
           */
          //this.$set(this.partsFileDataURL, partUUID, URL.createObjectURL(blob));

          if(!this.partsFileDataURL.hasOwnProperty(partUUID)) {
            this.partsFileDataURL[partUUID] = {};
          }
          this.partsFileDataURL[partUUID][extension] = URL.createObjectURL(blob);

          this.$set(this.partsLoading, partUUID, false);

          button.href = this.partsFileDataURL[partUUID][extension];
          setTimeout(() => {
            button.click();
          }, 250);
        });
        this.$downloadProgress.addDownload(req, this.partsDownloadName[partUUID], promise);
      } else {
        button.href = this.partsFileDataURL[partUUID][extension];
        setTimeout(() => {
          button.click();
        }, 250);
        setTimeout(() => {
          this.$set(this.partsLoading, partUUID, false);
        }, 1000);
      }
    },
    updateCSGStatus(timeout = 2000) {
      this.$apiInstance.getAdminOrder(this.$route.params.orderUUID).then(
        (orderData) => {
          this.orderCSGStatus = 'IDLE';
          orderData.itemsSerialNumbers.forEach((itemSerialNumber) => {
            if(itemSerialNumber.serialNumber) {
              if(itemSerialNumber.serialNumber.csgStatus === 'ERROR' || itemSerialNumber.serialNumber.csgStatus === 'INVALID') {
                this.orderCSGStatus = 'ERROR';
              } else if(itemSerialNumber.serialNumber.csgStatus === 'IN_PROGRESS' && this.orderCSGStatus !== 'ERROR') {
                this.orderCSGStatus = 'IN_PROGRESS';
              } else if(itemSerialNumber.serialNumber.csgStatus === 'VALID' && this.orderCSGStatus !== 'ERROR' && this.orderCSGStatus !== 'IN_PROGRESS') {
                this.orderCSGStatus = 'VALID';
              }
            }
            this.partsSerialNumbers[itemSerialNumber.part] = {
              code: itemSerialNumber.serialNumberCode,
              expiration: itemSerialNumber.expiration,
              serialNumber: itemSerialNumber.serialNumber
            };
          });
          if(this.orderCSGStatus !== 'VALID' && this.orderCSGStatus !== 'ERROR') {
            setTimeout(this.updateCSGStatus, Math.min(2 * timeout, 30000));
          }
        });
    },
    getOrder() {
      /**
       * GET ORDER
       */
      this.$apiInstance.getAdminOrder(this.$route.params.orderUUID).then(
        (orderData) => {

          for(const dispatch of orderData.quote.dispatch) {
            this.$set(this.dialogRemovePartsFromOrder, dispatch.supplier, false);
            this.$set(this.cancelPartSelected, dispatch.supplier, {});
            for(const item of dispatch.items) {
              if(item.part?._id) {
                this.$set(this.cancelPartSelected[dispatch.supplier], item.part._id, false);
              }
            }

            this.$set(this.dialogRemoveDispatchCustomLine, dispatch.supplier, false);
            this.$set(this.cancelDispatchCustomLineSelected, dispatch.supplier, {});
            for(const customLine of dispatch.customLines) {
              this.$set(this.cancelDispatchCustomLineSelected[dispatch.supplier], customLine._id, false);
            }
          }

          this.dialogRemoveOrderCustomLine = false;
          for(const customLine of orderData.quote.customLines) {
            this.$set(this.cancelOrderCustomLineSelected, customLine._id, false);
          }

          this.order = orderData;
          this.invoiceNumber = this.order.invoiceNumber;
          this.dispatchSupplier = null;
          this.numberOfPackage = null;
          this.numberOfPackageValidate = false;
          this.newPaymentMethod = orderData.paymentMethod;
          this.getSupplierDataForEachDispatch();
          
          /**
           * GET QUOTE
           */
          this.quote = this.order.quote;
          /**
           * Get serial number information for each part
           */
          this.orderCSGStatus = 'IDLE';
          orderData.itemsSerialNumbers.forEach((itemSerialNumber) => {
            if(itemSerialNumber.serialNumber) {
              if(itemSerialNumber.serialNumber.csgStatus === 'ERROR' || itemSerialNumber.serialNumber.csgStatus === 'INVALID') {
                this.orderCSGStatus = 'ERROR';
              } else if(itemSerialNumber.serialNumber.csgStatus === 'IN_PROGRESS' && this.orderCSGStatus !== 'ERROR') {
                this.orderCSGStatus = 'IN_PROGRESS';
              } else if(itemSerialNumber.serialNumber.csgStatus === 'VALID' && this.orderCSGStatus !== 'ERROR' && this.orderCSGStatus !== 'IN_PROGRESS') {
                this.orderCSGStatus = 'VALID';
              }
            }
            this.$set(this.partsSerialNumbers, itemSerialNumber.part, {
              code: itemSerialNumber.serialNumberCode,
              expiration: itemSerialNumber.expiration,
              serialNumber: itemSerialNumber.serialNumber
            });
            this.$set(this.partsLoading, itemSerialNumber.part, false);
          });
          orderData.quote.items.forEach(orderItem => {
            this.updateItemDownloadChoices(orderItem);
          });

          if(this.orderCSGStatus === 'IN_PROGRESS') {
            setTimeout(this.updateCSGStatus, 15000);
          }

          /**
           * Get purchase order information
           */
          this.purchaseOrder = this.order.quote.purchaseOrderNumber;
          
          /**
           * GET DELIVERYTRACKING INFORMATION
           */
          this.quote.dispatch.forEach((dispatch) => {
            if(!this.suppliersOrderPDFLoading.hasOwnProperty(dispatch.supplier)) {
              this.$set(this.suppliersOrderPDFLoading, dispatch.supplier, false);
            }
            if(!this.suppliersDeliveryOrderPDFLoading.hasOwnProperty(dispatch.supplier)) {
              this.$set(this.suppliersDeliveryOrderPDFLoading, dispatch.supplier, false);
            }
            if(!this.suppliersInvoicePDFLoading.hasOwnProperty(dispatch.supplier)) {
              this.$set(this.suppliersInvoicePDFLoading, dispatch.supplier, false);
            }
            dispatch.items.forEach((item) => {
              if(item.part?._id) {
                this.partsFileMetaData[item.part._id] = item.part.file;
                this.partsDownloadName[item.part._id] = (this.partsSerialNumbers[item.part._id] ? item.part.name + '_' + this.partsSerialNumbers[item.part._id].code : item.part.name) + '.stl';
              }
            });
            if(dispatch.deliveryTracking) {
              dispatch.deliveryTrackingUrl = dispatch.deliveryTracking.map(dT => {return dT.trackingNumber;}).join(',');
            }
          });

          this.dispatchesDataReady = true;

          /**
           * Check payment status
           */
          this.$apiInstance
            .getBrandOrderPayment(
              this.order.brand,
              this.$route.params.orderUUID
            )
            .then((paymentIntent) => {
              this.paymentIntent = paymentIntent;
              if (
                this.paymentIntent.status === 'succeeded' &&
                this.order.paymentStatus !== 'PAID'
              ) {
                this.updateOrderPaymentStatus();
              } else {
                this.currentOrderStatus();
              }
            });
        },
        (error) => {
          /**
           * ERROR GET ORDER
           */
          /**
           * Component BeelseNotifications used
           */
          this.$notification.notify('DANGER', ApiErrorParser.parse(error));
        }
      );
    },
    getSupplierOrderPDF(supplierUUID) {
      const linkRef = `supplierOrderPDFRef_${supplierUUID}`;
      const ref = this.$refs[linkRef][0];
      if(!this.suppliersOrderPDFData[supplierUUID] || true) {
        this.suppliersOrderPDFLoading[supplierUUID] = true;
        this.$apiInstance
          .getSupplierOrderPDF(
            supplierUUID,
            this.$route.params.orderUUID,
            {lang: this.$userLocale}
          ).then((result) => {
            if (result.data) {
              
              this.suppliersOrderPDFData[supplierUUID] = result.data;
              ref.href = `data:application/pdf;base64,${this.suppliersOrderPDFData[supplierUUID]}`;
              setTimeout(() => {
                ref.click();
              }, 250);
            } else {
              this.$notification.notify('DANGER', this.$t('CannotDownloadThisPDF'));
            }
          }, (error) => {
            this.$notification.notify('DANGER', ApiErrorParser.parse(error));
          }).finally(() => {
            this.suppliersOrderPDFLoading[supplierUUID] = false;
          });
      } else {
        ref.click();
      }
    },
    /**
     * GET SUPPLIER DELIVERY ORDER
     */
    getSupplierDeliveryOrderPDF(supplierUUID) {
      const linkRef = `supplierDeliveryOrderPDFRef_${supplierUUID}`;
      const ref = this.$refs[linkRef][0];
      if(!this.suppliersDeliveryOrderPDFData[supplierUUID]) {
        this.suppliersDeliveryOrderPDFLoading[supplierUUID] = true;
        this.$apiInstance.getSupplierDeliveryOrderPDF(
          supplierUUID,
          this.$route.params.orderUUID,
          {lang: this.$userLocale}
        )
          .then((result) => {
            if (result.data) {
              this.suppliersDeliveryOrderPDFData[supplierUUID] = result.data;
              ref.href = `data:application/pdf;base64,${this.suppliersDeliveryOrderPDFData[supplierUUID]}`;
              setTimeout(() => {
                ref.click();
              }, 250);
            } else {
              this.$notification.notify('DANGER', this.$t('CannotDownloadThisPDF'));
            }
          }, (error) => {
            this.$notification.notify('DANGER', ApiErrorParser.parse(error));
          }).finally(() => {
            this.suppliersDeliveryOrderPDFLoading[supplierUUID] = false;
          });
      } else {
        ref.click();
      }
    },
    /**
     * GET SUPPLIER ORDER INVOICE
     */
    getSupplierOrderInvoicePDF(supplierUUID) {
      const linkRef = `supplierInvoicePDFRef_${supplierUUID}`;
      const ref = this.$refs[linkRef][0];
      if(!this.suppliersInvoicePDFData[supplierUUID]) {
        this.suppliersInvoicePDFLoading[supplierUUID] = true;
        this.$apiInstance.getSupplierOrderInvoicePDF(
          supplierUUID,
          this.$route.params.orderUUID
        )
          .then((result) => {
            if (result.data) {
              this.suppliersInvoicePDFData[supplierUUID] = result.data;
              ref.href = `data:application/pdf;base64,${this.suppliersInvoicePDFData[supplierUUID]}`;
              setTimeout(() => {
                ref.click();
              }, 250);
            } else {
              this.$notification.notify('DANGER', this.$t('CannotDownloadThisPDF'));
            }
          }, (error) => {
            this.$notification.notify('DANGER', ApiErrorParser.parse(error));
          }).finally(() => {
            this.suppliersInvoicePDFLoading[supplierUUID] = false;
          });
      } else {
        ref.click();
      }
    },
    displayModalDeletion(dispatch) {
      this.modalDeletion = true;
      this.deleteDispatchSupplierInvoice = dispatch;
    },
    deleteSupplierOrderInvoice() {
      this.deletionInProgress = true;
      const dispatch = this.deleteDispatchSupplierInvoice;
      this.deleteDispatchSupplierInvoice = null;
      const supplierUUID = dispatch.supplier;
      const orderUUID = this.$route.params.orderUUID;
      this.$apiInstance
        .deleteAdminSupplierOrderInvoicePDF(supplierUUID, orderUUID)
        .finally(() => {
          this.getOrder();
          this.modalDeletion = false;
          this.deletionInProgress = false;
        });
    },
    /**
     * CHANGE THE STATUS OF THE PART
     */
    changePaymentMethod(method) {
      const newPaymentMethod = new this.$BcmModel.ModifyBrandOrderPaymentMethodBody(
        method
      );
      this.$apiInstance
        .modifyBrandOrderPaymentMethod(
          this.order.brand,
          this.$route.params.orderUUID,
          newPaymentMethod
        )
        .then(
          () => {
            this.$notification.notify('SUCCESS',this.$t('paymentMethodUpdated'));
            this.getOrder();
          },
          /**
           * ERROR GET BRAND PART
           */
          (error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    updateOrderPaymentStatus() {
      this.$apiInstance.updateBrandOrderPaymentStatus(
        this.order.brand,
        this.$route.params.orderUUID
      )
        .then(() => {

        }, (err) => {
          this.$notification.notify('ERROR', ApiErrorParser.parse(err));
        })
        .finally(() => {
          this.getOrder();
        });
    },
    validatePayment() {
      const newPaymentStatus = new this.$BcmModel.ModifyBrandOrderPaymentStatusBody(
        'PAID'
      );
      this.$apiInstance
        .modifyBrandOrderPaymentStatus(
          this.order.brand,
          this.$route.params.orderUUID,
          newPaymentStatus
        )
        .then(
          () => {
            this.$notification.notify('SUCCESS',this.$t('paymentStatusUpdated'));
            this.getOrder();
          },
          (error) => {
            /**
             * ERROR CHANGE ORDER STATUS
             */
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    cancelOrder() {
      this.$apiInstance.cancelBrandOrder(
        this.order.brand,
        this.$route.params.orderUUID
      ).then(() => {
        this.$notification.notify('SUCCESS', this.$t('OrderCanceledSuccessfully'));
      }).catch(err => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(err));
      }).finally(() => {
        this.$refs.cancelOrderRef.closeDialog();
        this.getOrder();
      });
    },
    getOrderPDF() {
      if(!this.pdfData) {
        this.loadingPdf = true;
        /**
         * GET ORDER PDF
         */
        this.$apiInstance
          .getBrandOrderPDF(this.order.brand, this.order._id)
          .then(
            (pdf) => {
              this.pdfData = pdf.data;
              this.$refs.invoicePDFRef.href = `data:application/pdf;base64,${this.pdfData}`;
              setTimeout(() => {
                this.$refs.invoicePDFRef.click();
              }, 250);
              
            },
            (error) => {
              /**
               * ERROR GET QUOTE PDF
               *
               * Component BeelseNotifications used
               */
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
              this.disabled = true;
            }
          )
          .finally(() => {
            this.loadingPdf = false;
          });
      } else {
        this.$refs.invoicePDFRef.click();
      }
    },
    currentOrderStatus() {
      if(this.order.status === 'CANCELED') {
        this.stepOrder = 0;
      } else if (
        this.order.paymentStatus !== 'PAID' &&
        this.order.paymentMethod === 'card'
      ) {
        this.stepOrder = 1;
      } else if (
        this.order.paymentMethod !== 'card' ||
        this.order.paymentStatus === 'PAID'
      ) {
        this.stepOrder = 2;
      }
      if (this.order.status === 'INPROGRESS') {
        this.stepOrder = 3;
      }
      if (this.order.status === 'DELIVERED') {
        this.stepOrder = 4;
      }
      for (const dispatch of this.order.quote.dispatch) {
        if (
          this.order.paymentStatus === 'PAID' &&
          dispatch.status === 'SCHEDULED'
        ) {
          dispatch.step = 1;
        } else if (
          this.order.paymentStatus !== 'PAID' &&
          dispatch.status === 'SCHEDULED'
        ) {
          dispatch.step = 0;
        }
        if (dispatch.status === 'MANUFACTURING') {
          dispatch.step = 2;
        }
        if (dispatch.status === 'MANUFACTURED') {
          dispatch.step = 3;
        }
        if (dispatch.status === 'MANUFACTURED' && dispatch.packageNumber) {
          dispatch.step = 4;
        }
        if (dispatch.status === 'DELIVERY') {
          dispatch.step = 5;
        }
        if (dispatch.status === 'DELIVERED') {
          dispatch.step = 6;
        }
      }
    },
    update(typeOfEvent, supplierUUID) {

      this.dispatchSupplier = supplierUUID;
      switch (typeOfEvent) {
      case 'SendToManufacturing':
        this.dialogForValidateNextStep2 = true;
        break;
      
      case 'ManufacturingValidation':
        this.dialogManufacturingValidation = true;
        break;
      
      case 'PackageReadyForShipment':
        this.nextStep(supplierUUID, typeOfEvent);
        break;
      
      case 'SendOrder':
        this.dialogForValidateNextStep4 = true;
        break;
      
      case 'DeliveryConfirmation':
        this.nextStep(supplierUUID, typeOfEvent);
        break;
      
      default:
        break;
      }
    },
    manufacturingValidation(supplierUUID) {

      const modifySupplierOrderDispatchStatusBody = new this.$BcmModel.ModifySupplierOrderDispatchStatusBody(
        'MANUFACTURED'
      );
      this.$apiInstance
        .modifySupplierOrderDispatchStatus(
          supplierUUID,
          this.$route.params.orderUUID,
          modifySupplierOrderDispatchStatusBody
        )
        .then(
          () => {
            this.getOrder();
            this.$notification.notify('SUCCESS',this.$t('statusUpdated'));
          },
          (error) => {
            /**
                     * ERROR CHANGE ORDER DISPATCH STATUS
                     */
            /**
                     * Component BeelseNotifications used
                     */
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    saveNumberOfPackages(supplierUUID) {
      if (
        !Number.isInteger(Number(this.numberOfPackage)) ||
        Number(this.numberOfPackage) <= 0
      ) {
        this.$notification.notify('DANGER', this.$t('invalidPackage'));
      } else {
        const numberPackage = Number(this.numberOfPackage);
        const changeOrderDispatchPackageNumberBySupplierBody = new this.$BcmModel.ChangeOrderDispatchPackageNumberBySupplierBody(
          numberPackage
        );
        this.$apiInstance
          .changeOrderDispatchPackageNumberBySupplier(
            supplierUUID,
            this.$route.params.orderUUID,
            changeOrderDispatchPackageNumberBySupplierBody
          )
          .then(
            () => {
              this.getOrder();
              
            },
            (error) => {
              /**
               * ERROR CHANGE ORDER DISPATCH PACKAGE NUMBER
               */
              /**
               * Component BeelseNotifications used
               */
              this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
            }
          );
      }
    },
    nextStep(supplierUUID, dispatchStep) {
      switch (dispatchStep) {
      case 'SendToManufacturing':
        this.dialogForValidateNextStep2 = false;
        this.manufacturingStatus(supplierUUID);
        break;
      case 'ManufacturingValidation':
        this.dialogManufacturingValidation = false;
        this.manufacturingValidation(supplierUUID);
        break;
      case 'PackageReadyForShipment':
        this.saveNumberOfPackages(supplierUUID);
        break;
      case 'SendOrder':
        this.dialogForValidateNextStep4 = false;
        this.deliveryStatus(supplierUUID);
        break;
      case 'DeliveryConfirmation':
        this.deliveredStatus(supplierUUID);
        break;
      default:
        break;
      }
    },
    manufacturingStatus(supplierUUID) {
      const changeOrderDispatchStatusBody = new this.$BcmModel.ModifySupplierOrderDispatchStatusBody(
        'MANUFACTURING'
      );
      this.$apiInstance
        .modifySupplierOrderDispatchStatus(
          supplierUUID,
          this.$route.params.orderUUID,
          changeOrderDispatchStatusBody
        )
        .then(
          () => {
            this.getOrder();
            this.$notification.notify('SUCCESS',this.$t('statusUpdated'));
          },
          (error) => {
            /**
             * ERROR CHANGE ORDER DISPATCH STATUS
             */
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    manufacturedStatus(supplierUUID) {
      if (
        !Number.isInteger(Number(this.numberOfPackage)) ||
        Number(this.numberOfPackage) <= 0
      ) {
        this.$notification.notify('DANGER', this.$t('invalidPackage'));
      } else {
        const numberPackage = Number(this.numberOfPackage);
        const changeOrderDispatchPackageNumberBody = new this.$BcmModel.ChangeOrderDispatchPackageNumberBySupplierBody(
          numberPackage
        );
        this.$apiInstance
          .changeOrderDispatchPackageNumberBySupplier(
            supplierUUID,
            this.$route.params.orderUUID,
            changeOrderDispatchPackageNumberBody
          )
          .then(
            (NumberOfPackageData) => {
              this.order = NumberOfPackageData;
              const modifySupplierOrderDispatchStatusBody = new this.$BcmModel.ModifySupplierOrderDispatchStatusBody(
                'MANUFACTURED'
              );
              this.$apiInstance
                .modifySupplierOrderDispatchStatus(
                  supplierUUID,
                  this.$route.params.orderUUID,
                  modifySupplierOrderDispatchStatusBody
                )
                .then(
                  () => {
                    this.getOrder();
                    this.$notification.notify('SUCCESS',this.$t('statusUpdated'));
                  },
                  (error) => {
                    /**
                     * ERROR CHANGE ORDER DISPATCH STATUS
                     */
                    /**
                     * Component BeelseNotifications used
                     */
                    this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
                  }
                );
            },
            (error) => {
              /**
               * ERROR CHANGE ORDER DISPATCH PACKAGE NUMBER
               */
              /**
               * Component BeelseNotifications used
               */
              this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
            }
          );
      }
    },
    deliveryStatus(supplierUUID) {
      const changeOrderDispatchStatusBody = new this.$BcmModel.ModifySupplierOrderDispatchStatusBody(
        'DELIVERY'
      );
      this.$apiInstance
        .modifySupplierOrderDispatchStatus(
          supplierUUID,
          this.$route.params.orderUUID,
          changeOrderDispatchStatusBody
        )
        .then(
          () => {
            this.getOrder();
            this.$notification.notify('SUCCESS',this.$t('statusUpdated'));
          },
          (error) => {
            /**
             * ERROR CHANGE ORDER DISPATCH STATUS
             */
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    deliveredStatus(supplierUUID) {
      const changeOrderDispatchStatusBody = new this.$BcmModel.ModifySupplierOrderDispatchStatusBody(
        'DELIVERED'
      );
      this.$apiInstance
        .modifySupplierOrderDispatchStatus(
          supplierUUID,
          this.$route.params.orderUUID,
          changeOrderDispatchStatusBody
        )
        .then(
          () => {
            this.getOrder();
          },
          (error) => {
            /**
             * ERROR CHANGE ORDER DISPATCH STATUS
             */
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    saveDeliveryTracking(dispatch, deliveryTrackingArray) {
      const deliveryTracking = [];
      deliveryTrackingArray.forEach((tracking) => {
        deliveryTracking.push(
          Object.assign(new this.$BcmModel.DeliveryTracking(), tracking)
        );
      });
      this.$apiInstance
        .changeOrderDispatchTrackingNumbers(
          dispatch.supplier,
          this.$route.params.orderUUID,
          deliveryTracking
        )
        .then(
          () => {
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('SUCCESS',this.$tc('TrackingNumberUpdated', deliveryTrackingArray.length));
            this.getOrder();
          },
          (error) => {
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    cancel() {
      this.getOrder();
    },
    linkToDeliveryTrackingWebsite(ref, url) {
      setTimeout(
        function() {
          url = this.$refs[ref][0].getURL();
          window.open(url);
        }.bind(this),
        200
      );
    },
    openDialogItemSerialNumberFileGeneration(part) {
      this.currentPart = part;
      this.dialogGenerateItemSerialNumberFile = true;
      this.expirationDate = false;
      this.expirationDays = 2;
    },
    closeDialogItemSerialNumberFileGeneration() {
      this.dialogGenerateItemSerialNumberFile = false;
      this.currentPart = null;
    },
    restartItemSerialNumberFileGeneration() {
      this.loadingAPI = true;
      delete this.partsFileDataURL[this.currentPart._id];
      let serialNumberCSGParametersBody = null;
      if(this.$refs.serialNumberCSGParametersSelectorRef?.[0]?.checkbox) {
        serialNumberCSGParametersBody = this.$BcmModel.SerialNumberCSGParametersBody.constructFromObject({
          size: this.$refs.serialNumberCSGParametersSelectorRef[0].customSize,
          height: this.$refs.serialNumberCSGParametersSelectorRef[0].customHeight
        });
      }
      const startOrderItemSerialNumberCSGBody = this.$BcmModel.StartOrderItemSerialNumberCSGBody.constructFromObject({
        ttl: this.expirationDate && this.expirationDays ? parseInt(this.expirationDays) * 86400 : -1,
        serialNumberCSGParameters: serialNumberCSGParametersBody
      });
      this.$apiInstance.startOrderItemSerialNumberCSG(
        this.order.brand,
        this.order._id,
        this.currentPart._id,
        startOrderItemSerialNumberCSGBody
      ).then(() => {
        this.closeDialogItemSerialNumberFileGeneration();
        this.updateCSGStatus();
        this.$notification.notify('SUCCESS', this.$t('RestartedSerialNumberFileGeneration'));
      }).catch((error) => {
        this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
      }).finally(() => {
        this.loadingAPI = false;
      });
    },
    openDialogOrderSerialNumberFilesGeneration() {
      this.dialogGenerateOrderSerialNumberFiles = true;
      this.expirationDate = false;
      this.expirationDays = 2;
    },
    closeDialogOrderSerialNumberFilesGeneration() {
      this.dialogGenerateOrderSerialNumberFiles = false;
    },
    restartOrderSerialNumberFilesGeneration() {
      this.partsFileDataURL = {};
      this.loadingAPI = true;
      const startOrderSerialNumbersCSGBody = new this.$BcmModel.StartOrderSerialNumbersCSGBody(this.expirationDate && this.expirationDays ? parseInt(this.expirationDays) * 86400 : -1);
      this.$apiInstance.startOrderSerialNumbersCSG(
        this.order.brand,
        this.order._id,
        startOrderSerialNumbersCSGBody
      ).then((data) => {
        this.closeDialogOrderSerialNumberFilesGeneration();
        this.updateCSGStatus();
        this.$notification.notify('SUCCESS', this.$t('RestartedSerialNumberFileGeneration'));
      }).catch((error) => {
        this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
      }).finally(() => {
        this.loadingAPI = false;
      });
    },
    openDialogDeleteSerialNumberFile(part) {
      this.currentPart = part;
      this.dialogDeleteSerialNumberFile = true;
    },
    closeDialogDeleteSerialNumberFile() {
      this.dialogDeleteSerialNumberFile = false;
      this.currentPart = null;
    },
    deleteSerialNumberFile() {
      this.loadingAPI = true;
      delete this.partsFileDataURL[this.currentPart.part];
      this.$apiInstance.deleteOrderItemSerialNumberFile(
        this.order.brand,
        this.order._id,
        this.currentPart._id,
      ).then((data) => {
        this.closeDialogDeleteSerialNumberFile();
        this.getOrder();
        this.$notification.notify('SUCCESS', this.$t('DeletedFile'));
      }).catch((error) => {
        this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));      }).finally(() => {
        this.loadingAPI = false;
      });
    },
    formattedOrderForSupplier(supplier) {
      const orderCopy = JSON.parse(JSON.stringify(this.order));
      orderCopy.quote.dispatch = orderCopy.quote.dispatch.find(d => {return d.supplier === supplier;});
      return orderCopy;
    },
    openDialogRemovePartsFromOrder(supplierUUID) {
      this.dialogRemovePartsFromOrder[supplierUUID] = true;
    },
    closeDialogRemovePartsFromOrder(supplierUUID) {
      this.dialogRemovePartsFromOrder[supplierUUID] = false;
    },
    removePartsFromOrderDispatch(supplierUUID) {
      this.loadingRemovePartsFromOrder = true;
      const reqBody = Object.keys(this.cancelPartSelected[supplierUUID])
        .filter(uuid => {return this.cancelPartSelected[supplierUUID][uuid] === true;})
        .map(uuid => {return new this.$BcmModel.CancelOrderDispatchPartBody(uuid);});

      this.$apiInstance.cancelOrderDispatchParts(
        this.$route.params.orderUUID,
        supplierUUID,
        reqBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('PartsOrderCanceledSuccessfully'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.loadingRemovePartsFromOrder = false;
        this.getOrder();
      });
    },
    openDialogRemoveCustomLinesFromDispatch(supplierUUID) {
      this.dialogRemoveDispatchCustomLine[supplierUUID] = true;
    },
    closeDialogRemoveCustomLinesFromDispatch(supplierUUID) {
      this.dialogRemoveDispatchCustomLine[supplierUUID] = false;
    },
    removeCustomLinesFromOrderDispatch(supplierUUID) {
      this.loadingRemoveCustomLine = true;
      const reqBody = Object.keys(this.cancelDispatchCustomLineSelected[supplierUUID])
        .filter(uuid => {return this.cancelDispatchCustomLineSelected[supplierUUID][uuid] === true;})
        .map(uuid => {return new this.$BcmModel.CancelOrderDispatchCustomLineBody(uuid);});

      this.$apiInstance.cancelOrderDispatchCustomLines(
        this.$route.params.orderUUID,
        supplierUUID,
        reqBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('CustomLinesCanceledSuccessfully'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.loadingRemoveCustomLine = false;
        this.getOrder();
      });
    },
    openDialogRemoveCustomLinesFromOrder() {
      this.dialogRemoveOrderCustomLine = true;
    },
    closeDialogRemoveCustomLinesFromOrder() {
      this.dialogRemoveOrderCustomLine = false;
    },
    removeCustomLinesFromOrder() {
      this.loadingRemoveCustomLine = true;
      const reqBody = Object.keys(this.cancelOrderCustomLineSelected)
        .filter(uuid => {return this.cancelOrderCustomLineSelected[uuid] === true;})
        .map(uuid => {return new this.$BcmModel.CancelOrderCustomLineBody(uuid);});

      this.$apiInstance.cancelOrderCustomLines(
        this.$route.params.orderUUID,
        reqBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('CustomLinesCanceledSuccessfully'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.loadingRemoveCustomLine = false;
        this.getOrder();
      });
    },
    payTheSupplier(dispatchUUID) {
      this.$apiInstance.createOrderDispatchPaymentTransfer(this.$route.params.orderUUID, dispatchUUID)
        .then((data) => {
          this.$notification.notify('SUCCESS',this.$t('supplierTransferCreated'));
        })
        .catch((error)=>{
          this.$notification.notify('ERROR',ApiErrorParser.parse(error));
        })
        .finally(()=>{
          this.dialogSupplierPayment[dispatchUUID] = false;
        });
    },
    openFileSelectorOrderPdf() {
      const fileSelector = this.$refs.selectPartOrderPdf;
      fileSelector.value = '';
      fileSelector.click();
    },
    addOrderInvoicePDF() {
      const fileSelector = this.$refs.selectPartOrderPdf;
      this.loadingPdf = true;
      if (fileSelector.value && fileSelector.files.length > 0) {
        const pdf = fileSelector.files[0];
        const splittedFileName = pdf.name.split('.');
        if (
          splittedFileName.length > 0 &&
          splittedFileName[splittedFileName.length - 1].toLocaleLowerCase() ===
          'pdf'
        ) {
          const fileReader = new FileReader();
          fileReader.addEventListener('loadend', () => {
            const base64PDF = fileReader.result.split('base64,')[1];
            const opts = {
              data: base64PDF, // Pdf | Invoice PDF file to add
            };
            this.$apiInstance
              .addOrderInvoicePDF(
                this.$route.params.orderUUID,
                opts
              )
              .then(() => {
                this.$notification.notify('SUCCESS', this.$t('InvoiceAdded'));
              }, (error) => {
                this.$notification.notify('ERROR', ApiErrorParser.parse(error));
              })
              .finally(() => {
                this.loadingPdf = false;
                this.getOrder();
              });
          });
          fileReader.readAsDataURL(pdf);
        }
      }
    },
    deleteOrderPDF() {
      this.loadingPdf = true;
      this.$apiInstance.deleteOrderInvoicePDF(
        this.$route.params.orderUUID
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('InvoiceHasBeenDeleted'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getOrder();
        this.loadingPdf = false;
      });
    },
    updateInvoiceNumber() {
      const modifyOrderInvoiceNumber = new this.$BcmModel.ModifyOrderInvoiceNumber();
      modifyOrderInvoiceNumber.invoiceNumber = this.invoiceNumber || null;

      this.$apiInstance.updateOrderInvoiceNumber(
        this.$route.params.orderUUID, 
        modifyOrderInvoiceNumber
      ).then(() => {
        this.$notification.notify('SUCCESS', this.$t('InvoiceNumberHasBeenUpdated'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getOrder();
      });
    }
  }
};
</script>
