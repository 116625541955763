var render = function render(){
  var _vm$order, _vm$order2, _vm$order3, _vm$partsSerialNumber14, _vm$partsSerialNumber15, _vm$currentPart, _vm$partsSerialNumber16, _vm$partsSerialNumber17, _vm$currentPart2, _vm$partsSerialNumber18, _vm$partsSerialNumber19, _vm$currentPart3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-order"
  }, [_vm.order ? _c('div', {
    staticClass: "page-order-screen"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', [_c('span', {
    staticClass: "text-wrap display-1 ma-3 ml-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('orderInformations')) + " ")])]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_vm.order && _vm.quote ? _c('div', {
    staticClass: "page-header-actions"
  }, [_vm.order.stripePaymentSession ? _c('v-btn', {
    staticClass: "white--text text-none ml-2 my-1",
    attrs: {
      "color": "stripe",
      "href": `${_vm.$appConfig.stripeURL}/payments/${_vm.order.stripePaymentSession}`,
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ViewOnStripe')) + " "), _c('v-icon', {
    staticClass: "ml-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-share-square ")])], 1) : _vm._e(), _vm.order.public ? [_c('v-btn', {
    staticClass: "text-none white--text ml-2",
    attrs: {
      "color": "primary",
      "loading": _vm.loadingPdf,
      "ripple": false,
      "disabled": (_vm$order = _vm.order) === null || _vm$order === void 0 ? void 0 : _vm$order.invoiceFile
    },
    on: {
      "click": _vm.openFileSelectorOrderPdf
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-upload ")]), _vm._v(" " + _vm._s(_vm.$t('addInvoice')) + " ")], 1), _c('input', {
    ref: "selectPartOrderPdf",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": ".pdf,application/pdf"
    },
    on: {
      "change": _vm.addOrderInvoicePDF
    }
  }), _c('a', {
    ref: "invoicePDFRef",
    staticClass: "d-none",
    attrs: {
      "download": `Invoice_CMT_${_vm.order.invoiceNumber}`
    }
  }), _c('v-btn', {
    staticClass: "text-none white--text ml-2",
    attrs: {
      "color": "orange",
      "loading": _vm.loadingPdf,
      "ripple": false,
      "disabled": !((_vm$order2 = _vm.order) !== null && _vm$order2 !== void 0 && _vm$order2.invoiceFile)
    },
    on: {
      "click": _vm.getOrderPDF
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-file-pdf ")]), _vm._v(" " + _vm._s(_vm.$t('exportInvoice')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none white--text ml-2",
    attrs: {
      "color": "error",
      "loading": _vm.loadingPdf,
      "ripple": false,
      "disabled": !((_vm$order3 = _vm.order) !== null && _vm$order3 !== void 0 && _vm$order3.invoiceFile)
    },
    on: {
      "click": _vm.deleteOrderPDF
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-trash ")])], 1)] : _vm._e()], 2) : _vm._e()])], 1), _c('v-divider', {
    staticClass: "mb-2 mt-4"
  }), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.order ? _c('Steppers', {
    key: `orderStatus${_vm.order.status}`,
    staticClass: "w-100",
    attrs: {
      "stepper": _vm.stepOrder,
      "steps": _vm.wizardItemsOrder
    }
  }) : _vm._e()], 1)], 1), _vm.order.status === 'CANCELED' ? _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }) : _vm._e(), _vm.order.status === 'CANCELED' ? _c('v-alert', {
    staticClass: "text-center",
    attrs: {
      "outlined": "",
      "prominent": "",
      "text": "",
      "type": "info"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('OrderHasBeenCanceled')) + " ")]) : _vm._e(), _c('v-divider', {
    staticClass: "my-2"
  }), _vm.order.public && _vm.order.status === 'NEW' && (_vm.order.paymentMethod !== 'card' || _vm.order.paymentStatus !== 'PAID') ? _c('div', {
    staticClass: "paymentMethod d-flex paymentMethodAnchor",
    staticStyle: {
      "position": "relative",
      "z-index": "1"
    }
  }, [_c('div', {
    staticClass: "align-self-center mr-2"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('ChoosePayment')))])]), _c('v-menu', {
    attrs: {
      "offset-y": "",
      "attach": ".paymentMethodAnchor"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "white--text dropdownButton",
          attrs: {
            "color": "orange",
            "ripple": false
          }
        }, on), [_vm._v(" " + _vm._s(_vm.$t(_vm.newPaymentMethod)) + " ")])];
      }
    }], null, false, 1550231773)
  }, [_c('v-list', {
    staticClass: "py-0"
  }, [_vm._l(_vm.methodsList, function (method, i) {
    return [method !== _vm.newPaymentMethod ? _c('v-list-item', {
      key: i,
      on: {
        "click": function ($event) {
          _vm.newPaymentMethod = method;
        }
      }
    }, [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t(method)))])], 1) : _vm._e()];
  })], 2)], 1), _c('div', {
    staticClass: "justify-content-end"
  }, [_c('v-btn', {
    staticClass: "text-none ml-2",
    attrs: {
      "color": "success"
    },
    on: {
      "click": function ($event) {
        return _vm.changePaymentMethod(_vm.newPaymentMethod);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Validate')) + " ")], 1)], 1), _c('v-flex', {
    staticClass: "d-flex justify-end"
  }, [_vm.order.paymentMethod === 'card' && _vm.order.paymentStatus !== 'PAID' ? _c('v-btn', {
    staticClass: "text-none ml-2",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.updateOrderPaymentStatus
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-sync ")]), _vm._v(" " + _vm._s(_vm.$t('SyncStripePaymentStatus')) + " ")], 1) : _vm._e(), _c('v-btn', {
    staticClass: "text-none ml-2",
    attrs: {
      "color": "success",
      "disabled": _vm.order.paymentMethod !== 'manual' && _vm.order.paymentMethod !== 'transfer'
    },
    on: {
      "click": _vm.validatePayment
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('ValidatePayment')) + " ")], 1), _vm.order.status === 'NEW' && _vm.order.paymentStatus !== 'PAID' ? _c('v-btn', {
    staticClass: "ml-2 text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.cancelOrderRef.openDialog();
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('CancelOrder')) + " ")], 1) : _vm._e(), _vm.order ? _c('CancelOrder', {
    ref: "cancelOrderRef",
    attrs: {
      "order": _vm.order
    },
    on: {
      "cancelOrder": _vm.cancelOrder
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), !_vm.order.public ? _c('v-alert', {
    staticClass: "text-center",
    attrs: {
      "type": "info",
      "prominent": "",
      "text": "",
      "outlined": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('PrivateOrderInformation')) + " ")]) : _vm._e(), _vm.order && _vm.quote ? [_c('div', {
    staticClass: "orderInfo"
  }, [_c('p', [_c('strong', [_vm._v(_vm._s(_vm.$t('quote:')))]), _c('router-link', {
    staticClass: "text-decoration-none",
    attrs: {
      "to": {
        name: 'Quote',
        params: {
          quoteUUID: `${_vm.quote._id}`
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.quote.quoteNumber) + " ")])], 1), _c('p', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('created:')))]), _vm._v(" " + _vm._s(_vm.$d(new Date(_vm.order.created), 'long')) + " ")]), _c('p', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('invoiceID:')))]), _vm.order.public ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": ""
    },
    model: {
      value: _vm.invoiceNumber,
      callback: function ($$v) {
        _vm.invoiceNumber = $$v;
      },
      expression: "invoiceNumber"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm.invoiceNumber !== _vm.order.invoiceNumber ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "small": "",
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.invoiceNumber = _vm.order.invoiceNumber;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")])], 1) : _vm._e()], 1), _c('template', {
    slot: "append-outer"
  }, [_vm.invoiceNumber !== _vm.order.invoiceNumber ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "small": "",
      "color": "success"
    },
    on: {
      "click": _vm.updateInvoiceNumber
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")])], 1) : _vm._e()], 1)], 2)], 1)], 1) : [_vm._v(" " + _vm._s(_vm.order.invoiceNumber) + " ")]], 2), _c('p', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('purchaseOrderNumber:')))]), _vm.order.quote.purchaseOrderNumber ? _c('span', [_vm._v(" " + _vm._s(_vm.order.quote.purchaseOrderNumber) + " ")]) : _c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v(" " + _vm._s(_vm.$t('NotSpecified')) + " ")])]), _c('p', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('TotalAmountVATIncluded')) + " : ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.order.quote.selling.totalAmount, 'currency', _vm.$userLocale)) + " ")])])]), _c('v-divider', {
    staticClass: "my-5"
  }), _c('v-expansion-panels', {
    staticClass: "mb-4"
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('div', {
    staticClass: "text-h6"
  }, [_c('v-icon', {
    staticClass: "mr-4",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-file-signature ")]), _vm._v(" " + _vm._s(_vm.$t('Quote')) + " ")], 1)]), _c('v-expansion-panel-content', [_c('BeelseQuote', {
    attrs: {
      "quote": _vm.order.quote,
      "custom-flat": true,
      "admin-mode": true
    }
  })], 1)], 1)], 1)] : _vm._e(), Object.keys(_vm.partsSerialNumbers).length > 0 ? _c('v-alert', {
    staticClass: "text-h7 text-center align-center mt-4 px-12 primary--text",
    attrs: {
      "prominent": "",
      "color": "orange",
      "border": "left",
      "elevation": "2",
      "colored-border": "",
      "icon": "fas fa-info"
    },
    scopedSlots: _vm._u([{
      key: "close",
      fn: function () {
        return [_c('div', [_c('v-row', [_c('v-col', {
          staticClass: "pt-0 pb-1 justify-center text-h7"
        }, [_c('span', [_vm._v(_vm._s(_vm.$tc('FileStatus', 2)))])])], 1), _c('v-row', [_c('v-col', {
          staticClass: "py-0"
        }, [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on;
              return [_c('span', _vm._g({}, on), [_c('PythonStatus', {
                attrs: {
                  "status": _vm.orderCSGStatus,
                  "size": "small"
                },
                on: {
                  "pythonActionRequired": _vm.openDialogOrderSerialNumberFilesGeneration
                }
              })], 1)];
            }
          }], null, false, 1411093769)
        }, [_c('span', [_vm._v(_vm._s(_vm.tooltipsCSG[_vm.orderCSGStatus]))])])], 1)], 1)], 1)];
      },
      proxy: true
    }], null, false, 2474882494)
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('PartsWithSerialNumberMessage1')))]), _c('v-row', {
    staticClass: "mb-0",
    attrs: {
      "justify": "center"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('PartsWithSerialNumberMessage2')))]), _c('v-img', {
    staticClass: "ml-1",
    attrs: {
      "src": require("../../../public/img/icons/cubeBCM.png"),
      "alt": "cubeBCM",
      "max-height": "28px",
      "max-width": "28px",
      "contain": ""
    }
  })], 1)], 1) : _vm._e(), _vm._l(_vm.order.quote.dispatch, function (dispatch, index) {
    var _vm$dispatchSupplierL, _vm$dispatchSupplierL2, _vm$dispatchSupplierL3, _vm$dispatchSupplierL4, _dispatch$deliveryTra;
    return _c('v-card', {
      key: index,
      staticClass: "mb-4"
    }, [_c('v-card-title', [_c('v-row', [_vm.dispatchSupplierList.length > 0 ? _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', [_c('v-icon', {
      staticClass: "mr-4",
      attrs: {
        "color": "info"
      }
    }, [_vm._v(" fas fa-truck ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('dispatchTitle', {
      dispatchIndex: index + 1
    })) + " ")]), dispatch.supplier !== 'MANUAL' ? _c('v-btn', {
      staticClass: "white--text text-none headline pa-0 pb-1",
      attrs: {
        "color": "primary",
        "text": "",
        "ripple": false,
        "to": {
          name: 'SupplierGeneral',
          params: {
            supplierUUID: `${dispatch.supplier}`
          }
        }
      }
    }, [_vm._v(" " + _vm._s((_vm$dispatchSupplierL = _vm.dispatchSupplierList[index]) === null || _vm$dispatchSupplierL === void 0 ? void 0 : _vm$dispatchSupplierL.name) + " ")]) : _c('span', [_vm._v(_vm._s((_vm$dispatchSupplierL2 = _vm.dispatchSupplierList[index]) === null || _vm$dispatchSupplierL2 === void 0 ? void 0 : _vm$dispatchSupplierL2.name))])], 1), _c('v-dialog', {
      attrs: {
        "persistent": "",
        "max-width": "600",
        "id": `transfer_dialog_${dispatch.supplier}`
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref3) {
          var _vm$paymentIntent;
          var on = _ref3.on,
            attrs = _ref3.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            staticClass: "white--text text-none ml-2 my-1",
            attrs: {
              "disabled": ((_vm$paymentIntent = _vm.paymentIntent) === null || _vm$paymentIntent === void 0 ? void 0 : _vm$paymentIntent.status) !== 'succeeded',
              "color": "stripe"
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', {
            staticClass: "mr-2",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" fas fa-money-check-alt ")]), _vm._v(" " + _vm._s(_vm.$t('payTheSupplier')) + " "), _c('span', {
            staticClass: "ml-2"
          }, [_vm._v("(" + _vm._s(_vm.$n(dispatch.purchase.totalAmount, 'currency')) + ")")])], 1)];
        }
      }], null, true),
      model: {
        value: _vm.dialogSupplierPayment[dispatch.supplier],
        callback: function ($$v) {
          _vm.$set(_vm.dialogSupplierPayment, dispatch.supplier, $$v);
        },
        expression: "dialogSupplierPayment[dispatch.supplier]"
      }
    }, [_c('v-card', [_c('v-card-title', {
      staticClass: "text-h5"
    }, [_vm._v(" " + _vm._s(_vm.$t('payTheSupplier')) + " ")]), _c('v-card-text', [_vm._v(_vm._s(_vm.$t('transferWillBeCreated')))]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      staticClass: "text-none mr-2",
      attrs: {
        "color": "error"
      },
      on: {
        "click": function ($event) {
          _vm.dialogSupplierPayment[dispatch.supplier] = false;
        }
      }
    }, [_c('v-icon', {
      staticClass: "mx-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-times ")]), _vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")], 1), _c('v-btn', {
      staticClass: "white--text text-none ml-2 my-1",
      attrs: {
        "color": "stripe"
      },
      on: {
        "click": function ($event) {
          return _vm.payTheSupplier(dispatch.supplier);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-money-check-alt ")]), _vm._v(" " + _vm._s(_vm.$t('payTheSupplier')) + " "), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("(" + _vm._s(_vm.$n(dispatch.purchase.totalAmount, 'currency')) + ")")])], 1)], 1)], 1)], 1), (_vm$dispatchSupplierL3 = _vm.dispatchSupplierList[index]) !== null && _vm$dispatchSupplierL3 !== void 0 && _vm$dispatchSupplierL3.stripeId ? _c('v-btn', {
      staticClass: "white--text text-none ml-2 my-1",
      attrs: {
        "color": "stripe",
        "href": `${_vm.$appConfig.stripeURL}/connect/accounts/${(_vm$dispatchSupplierL4 = _vm.dispatchSupplierList[index]) === null || _vm$dispatchSupplierL4 === void 0 ? void 0 : _vm$dispatchSupplierL4.stripeId}/activity`,
        "target": "_blank"
      }
    }, [_vm._v(" Stripe Connect "), _c('v-icon', {
      staticClass: "ml-1",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-share-square ")])], 1) : _vm._e()], 1) : _vm._e(), _c('v-col', {
      staticClass: "d-flex flex-wrap justify-end"
    }, [_c('a', {
      ref: `supplierOrderPDFRef_${dispatch.supplier}`,
      refInFor: true,
      staticClass: "d-none",
      attrs: {
        "download": `Supplier_${dispatch.supplier}_CMT_BC_${_vm.order.invoiceNumber}`
      }
    }), _c('v-btn', {
      staticClass: "text-none white--text ml-2 my-1",
      attrs: {
        "color": "orange",
        "loading": _vm.suppliersOrderPDFLoading[dispatch.supplier]
      },
      on: {
        "click": function ($event) {
          return _vm.getSupplierOrderPDF(dispatch.supplier);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-file-pdf ")]), _vm._v(" " + _vm._s(_vm.$t('supplierOrder')) + " ")], 1), _c('a', {
      ref: `supplierDeliveryOrderPDFRef_${dispatch.supplier}`,
      refInFor: true,
      staticClass: "d-none",
      attrs: {
        "download": `Supplier_${dispatch.supplier}_CMT_BL_${_vm.order.invoiceNumber}`
      }
    }), _c('v-btn', {
      staticClass: "text-none white--text ml-2 my-1",
      attrs: {
        "color": "orange",
        "loading": _vm.suppliersDeliveryOrderPDFLoading[dispatch.supplier]
      },
      on: {
        "click": function ($event) {
          return _vm.getSupplierDeliveryOrderPDF(dispatch.supplier);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-file-pdf ")]), _vm._v(" " + _vm._s(_vm.$t('supplierDeliveryOrder')) + " ")], 1), _c('a', {
      ref: `supplierInvoicePDFRef_${dispatch.supplier}`,
      refInFor: true,
      staticClass: "d-none",
      attrs: {
        "download": `Supplier_${dispatch.supplier}_Invoice_${_vm.order.invoiceNumber}`
      }
    }), _c('v-btn', {
      staticClass: "text-none white--text ml-2 my-1",
      attrs: {
        "color": "orange",
        "loading": _vm.suppliersInvoicePDFLoading[dispatch.supplier],
        "disabled": !dispatch.invoiceFile
      },
      on: {
        "click": function ($event) {
          return _vm.getSupplierOrderInvoicePDF(dispatch.supplier);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-file-pdf ")]), _vm._v(" " + _vm._s(_vm.$t('supplierInvoice')) + " ")], 1), _c('v-btn', {
      staticClass: "text-none white--text ml-2 my-1",
      attrs: {
        "color": "error",
        "disabled": !dispatch.invoiceFile
      },
      on: {
        "click": function ($event) {
          return _vm.displayModalDeletion(dispatch);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-trash ")])], 1)], 1)], 1)], 1), dispatch && _vm.dispatchesDataReady ? _c('v-card-text', [_c('v-row', {
      attrs: {
        "align": "center",
        "justify": "space-between"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('SupplierOrderStepper', {
      key: dispatch.supplier + 'orderStepper',
      staticClass: "elevation-2 white rounded",
      class: dispatch.step === 3 && (!_vm.numberOfPackage || _vm.numberOfPackage <= 0 || !_vm.numberOfPackageValidate) || dispatch.step === 4 && !((_dispatch$deliveryTra = dispatch.deliveryTracking) !== null && _dispatch$deliveryTra !== void 0 && _dispatch$deliveryTra.length) ? 'disabledStepper' : '',
      attrs: {
        "admin": "",
        "order": _vm.formattedOrderForSupplier(dispatch.supplier),
        "supplier-u-u-i-d": dispatch.supplier
      },
      on: {
        "updateStepper": _vm.update
      }
    })], 1)], 1), dispatch.step === 3 ? _c('v-row', {
      staticClass: "d-flex align-center"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "text-subtitle-1"
    }, [_vm._v(" " + _vm._s(_vm.$t('NumberOfPackageForThisOrder')) + " ")])]), _c('v-col', {
      attrs: {
        "cols": "2",
        "md": "1"
      }
    }, [_c('v-text-field', {
      attrs: {
        "dense": "",
        "hide-details": "auto",
        "outlined": "",
        "type": "number",
        "append-outer-icon": "fas fa-check success--text"
      },
      on: {
        "click:append-outer": function ($event) {
          _vm.numberOfPackageValidate = true;
        }
      },
      model: {
        value: _vm.numberOfPackage,
        callback: function ($$v) {
          _vm.numberOfPackage = $$v;
        },
        expression: "numberOfPackage"
      }
    })], 1)], 1) : _vm._e(), dispatch.step === 2 ? _c('v-dialog', {
      attrs: {
        "primary-title": "",
        "max-width": "600",
        "persistent": ""
      },
      model: {
        value: _vm.dialogManufacturingValidation,
        callback: function ($$v) {
          _vm.dialogManufacturingValidation = $$v;
        },
        expression: "dialogManufacturingValidation"
      }
    }, [_c('v-card', [_c('v-card-title', {
      staticClass: "headline"
    }, [_vm._v(" " + _vm._s(_vm.$t('ManufacturingValidation')) + " ")]), _c('v-divider'), _c('v-card', {
      staticClass: "py-4 px-6",
      attrs: {
        "elevation": "0"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('reallyWantToValidateManufacturing')) + " ")]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      staticClass: "text-none",
      attrs: {
        "color": "error"
      },
      on: {
        "click": function ($event) {
          _vm.dialogManufacturingValidation = false;
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t('close')) + " ")], 1), _c('v-btn', {
      staticClass: "text-none",
      attrs: {
        "color": "success"
      },
      on: {
        "click": function ($event) {
          return _vm.nextStep(_vm.dispatchSupplier, 'ManufacturingValidation');
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('ManufacturingValidationButton')) + " "), _c('v-icon', {
      staticClass: "ml-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-arrow-right ")])], 1)], 1)], 1)], 1) : _vm._e(), _c('v-dialog', {
      attrs: {
        "primary-title": "",
        "max-width": "600",
        "persistent": ""
      },
      model: {
        value: _vm.dialogForValidateNextStep2,
        callback: function ($$v) {
          _vm.dialogForValidateNextStep2 = $$v;
        },
        expression: "dialogForValidateNextStep2"
      }
    }, [_c('v-card', [_c('v-card-title', {
      staticClass: "headline"
    }, [_vm._v(" " + _vm._s(_vm.$t('manufacturing')) + " ")]), _c('v-divider'), _c('v-card', {
      staticClass: "py-4 px-6",
      attrs: {
        "elevation": "0"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('reallyWantToGoNextStepManufacturing')) + " ")]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      staticClass: "text-none",
      attrs: {
        "color": "error"
      },
      on: {
        "click": function ($event) {
          _vm.dialogForValidateNextStep2 = false;
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t('close')) + " ")], 1), _c('v-btn', {
      staticClass: "text-none",
      attrs: {
        "color": "success"
      },
      on: {
        "click": function ($event) {
          return _vm.nextStep(_vm.dispatchSupplier, 'SendToManufacturing');
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('SendToManufacturing')) + " "), _c('v-icon', {
      staticClass: "ml-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-arrow-right ")])], 1)], 1)], 1)], 1), _c('v-dialog', {
      attrs: {
        "primary-title": "",
        "max-width": "600",
        "persistent": ""
      },
      model: {
        value: _vm.dialogForValidateNextStep4,
        callback: function ($$v) {
          _vm.dialogForValidateNextStep4 = $$v;
        },
        expression: "dialogForValidateNextStep4"
      }
    }, [_c('v-card', [_c('v-card-title', {
      staticClass: "headline"
    }, [_vm._v(" " + _vm._s(_vm.$t('delivery')) + " ")]), _c('v-divider', {
      staticClass: "mb-1"
    }), _c('v-card', {
      staticClass: "py-4 px-6",
      attrs: {
        "elevation": "0"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('reallyWantToGoNextStepDelivery')) + " ")]), _c('v-divider', {
      staticClass: "mb-1"
    }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      staticClass: "text-none",
      attrs: {
        "color": "info"
      },
      on: {
        "click": function ($event) {
          _vm.dialogForValidateNextStep4 = false;
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t('close')) + " ")], 1), _c('v-btn', {
      staticClass: "text-none",
      attrs: {
        "color": "success"
      },
      on: {
        "click": function ($event) {
          return _vm.nextStep(_vm.dispatchSupplier, 'SendOrder');
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-shipping-fast ")]), _vm._v(" " + _vm._s(_vm.$t('SendTheOrder')) + " ")], 1)], 1)], 1)], 1), dispatch.step >= 4 ? _c('div', {
      staticClass: "module-deliveryTracking pt-2"
    }, [_c('v-expansion-panels', [_c('v-expansion-panel', [_c('div', {
      staticClass: "mb-0"
    }, [_c('v-expansion-panel-header', {
      staticClass: "d-flex justify-space-between px-0 px-sm-3"
    }, [_c('h5', {
      staticClass: "orange--text title col"
    }, [_c('v-icon', {
      staticClass: "mb-1 mr-3",
      attrs: {
        "small": "",
        "color": "orange"
      }
    }, [_vm._v(" fas fa-box-open ")]), _vm._v(" " + _vm._s(_vm.$t('DeliveryTracking')) + " "), dispatch.deliveryOrder ? _c('v-chip', {
      staticClass: "ml-4"
    }, [_vm._v(" " + _vm._s(_vm.$t('deliveryOrder:', {
      deliveryOrder: dispatch.deliveryOrder
    })) + " ")]) : _vm._e()], 1), dispatch.deliveryTrackingUrl ? _c('v-btn', {
      staticClass: "text-none",
      attrs: {
        "text": "",
        "ripple": false,
        "color": "orange"
      },
      on: {
        "click": function ($event) {
          return _vm.linkToDeliveryTrackingWebsite(`myDeliveryTracking${dispatch.supplier}`, dispatch.deliveryTrackingUrl);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-external-link-alt ")]), _c('span', {
      staticClass: "d-none d-md-block"
    }, [_vm._v(" " + _vm._s(_vm.$t('ViewTracking')) + " ")])], 1) : _vm._e()], 1)], 1), _c('v-expansion-panel-content', [_c('v-divider'), _c('DeliveryTrackingV2', {
      ref: `myDeliveryTracking${dispatch.supplier}`,
      refInFor: true,
      staticClass: "ma-5",
      attrs: {
        "order": _vm.formattedOrderForSupplier(dispatch.supplier),
        "readonly": dispatch.status !== 'DELIVERY' && dispatch.status !== 'MANUFACTURED'
      },
      on: {
        "save": function (deliveryTracking) {
          _vm.saveDeliveryTracking(dispatch, deliveryTracking);
        }
      }
    })], 1)], 1)], 1)], 1) : _vm._e(), dispatch.step !== 6 && dispatch.step > 1 ? _c('v-divider', {
      staticClass: "my-3"
    }) : _vm._e(), _c('v-simple-table', [_c('thead', [_c('tr', [_c('th', [_vm._v(" " + _vm._s(_vm.$t('part')) + " ")]), _c('th', [_vm._v(" " + _vm._s(_vm.$t('quantity')) + " ")]), _c('th', [_vm._v(" " + _vm._s(_vm.$t('SerialNumber')) + " ")]), _c('th', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(_vm.$tc('FileStatus', 1)) + " ")]), _c('th', {
      staticClass: "text-center"
    }, [_c('v-btn', {
      staticClass: "text-none",
      attrs: {
        "small": "",
        "color": "error",
        "disabled": !Object.values(_vm.cancelPartSelected[dispatch.supplier]).some(function (b) {
          return !!b;
        })
      },
      on: {
        "click": function ($event) {
          return _vm.openDialogRemovePartsFromOrder(dispatch.supplier);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-times-circle ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('CancelSelectedParts')) + " ")])], 1)], 1), _c('th')])]), _c('tbody', _vm._l(dispatch.items, function (item, index) {
      var _item$part2, _item$part3, _item$part4, _item$part5, _vm$partsSerialNumber, _item$part6, _vm$partsSerialNumber4, _vm$partsSerialNumber5, _item$part8, _vm$partsSerialNumber6, _item$part9, _vm$partsSerialNumber7, _vm$partsSerialNumber8, _item$part10, _vm$partsSerialNumber9, _item$part11, _item$part13, _item$part15, _item$part16, _item$part17, _item$part18, _item$part19, _item$part20, _item$part21, _item$part22, _vm$partsSerialNumber12, _vm$partsSerialNumber13, _item$part23, _item$part24;
      return _c('tr', {
        key: index,
        class: {
          'canceled-row': item.canceled
        }
      }, [_c('td', [_c('div', {
        staticClass: "d-flex align-center"
      }, [_c('v-tooltip', {
        attrs: {
          "top": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function (_ref4) {
            var _item$part;
            var on = _ref4.on;
            return [_vm.partsSerialNumbers[(_item$part = item.part) === null || _item$part === void 0 ? void 0 : _item$part._id] ? _c('v-img', _vm._g({
              staticClass: "ml-n1 mr-1",
              attrs: {
                "src": require("../../../public/img/icons/cubeBCM.png"),
                "alt": "cubeBCM",
                "height": "24px",
                "max-width": "30px",
                "min-width": "30px",
                "contain": ""
              }
            }, on)) : _vm._e()];
          }
        }], null, true)
      }, [_c('span', [_vm._v(_vm._s(_vm.$t('PartWithSerialNumber')))])]), _c((_item$part2 = item.part) !== null && _item$part2 !== void 0 && _item$part2._id ? 'router-link' : 'span', {
        tag: "router-link",
        staticClass: "text-decoration-none d-block",
        staticStyle: {
          "overflow-wrap": "anywhere"
        },
        attrs: {
          "to": {
            name: 'Part',
            params: {
              brandUUID: `${_vm.order.quote.brand}`,
              partUUID: `${(_item$part3 = item.part) === null || _item$part3 === void 0 ? void 0 : _item$part3._id}`
            }
          }
        }
      }, [_vm._v(" " + _vm._s(((_item$part4 = item.part) === null || _item$part4 === void 0 ? void 0 : _item$part4.name) || 'DELETED_PART') + " ")]), item.canceled ? _c('span', {
        staticClass: "ml-2 text-h6 font-weight-bold error--text"
      }, [_vm._v(" [" + _vm._s(_vm.$t('Canceled').toUpperCase()) + "] ")]) : _vm._e()], 1)]), _c('td', [_c('span', [_vm._v(" " + _vm._s(item.quantity) + " ")]), item.canceled ? _c('span', {
        staticClass: "ml-2 text-h6 font-weight-bold error--text"
      }, [_vm._v(" -> 0 ")]) : _vm._e()]), _c('td', [_vm.partsSerialNumbers[(_item$part5 = item.part) === null || _item$part5 === void 0 ? void 0 : _item$part5._id] ? _c('span', [_vm._v(" " + _vm._s((_vm$partsSerialNumber = _vm.partsSerialNumbers[item.part._id]) === null || _vm$partsSerialNumber === void 0 ? void 0 : _vm$partsSerialNumber.code) + " ")]) : _c('span', [_vm._v("-")])]), _c('td', [_vm.partsSerialNumbers[(_item$part6 = item.part) === null || _item$part6 === void 0 ? void 0 : _item$part6._id] ? _c('div', {
        staticClass: "d-flex justify-center align-center"
      }, [_c('v-tooltip', {
        attrs: {
          "top": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function (_ref5) {
            var _vm$partsSerialNumber2, _vm$partsSerialNumber3, _item$part7;
            var on = _ref5.on;
            return [_c('span', _vm._g({}, on), [_c('PythonStatus', {
              attrs: {
                "size": "x-small",
                "status": (_vm$partsSerialNumber2 = _vm.partsSerialNumbers[(_item$part7 = item.part) === null || _item$part7 === void 0 ? void 0 : _item$part7._id]) === null || _vm$partsSerialNumber2 === void 0 ? void 0 : (_vm$partsSerialNumber3 = _vm$partsSerialNumber2.serialNumber) === null || _vm$partsSerialNumber3 === void 0 ? void 0 : _vm$partsSerialNumber3.csgStatus
              },
              on: {
                "pythonActionRequired": function ($event) {
                  return _vm.openDialogItemSerialNumberFileGeneration(item.part);
                }
              }
            })], 1)];
          }
        }], null, true)
      }, [_c('span', [_vm._v(" " + _vm._s(_vm.tooltipsCSG[(_vm$partsSerialNumber4 = _vm.partsSerialNumbers[(_item$part8 = item.part) === null || _item$part8 === void 0 ? void 0 : _item$part8._id]) === null || _vm$partsSerialNumber4 === void 0 ? void 0 : (_vm$partsSerialNumber5 = _vm$partsSerialNumber4.serialNumber) === null || _vm$partsSerialNumber5 === void 0 ? void 0 : _vm$partsSerialNumber5.csgStatus]) + " ")])]), !((_vm$partsSerialNumber6 = _vm.partsSerialNumbers[(_item$part9 = item.part) === null || _item$part9 === void 0 ? void 0 : _item$part9._id]) !== null && _vm$partsSerialNumber6 !== void 0 && _vm$partsSerialNumber6.expiration) || !((_vm$partsSerialNumber7 = _vm.partsSerialNumbers[(_item$part10 = item.part) === null || _item$part10 === void 0 ? void 0 : _item$part10._id]) !== null && _vm$partsSerialNumber7 !== void 0 && (_vm$partsSerialNumber8 = _vm$partsSerialNumber7.serialNumber) !== null && _vm$partsSerialNumber8 !== void 0 && _vm$partsSerialNumber8.file) ? _c('v-icon', {
        staticClass: "pl-3",
        attrs: {
          "disabled": ""
        }
      }, [_vm._v(" fas fa-calendar-alt ")]) : _c('v-tooltip', {
        attrs: {
          "top": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function (_ref6) {
            var on = _ref6.on;
            return [_c('v-icon', _vm._g({
              staticClass: "pl-3",
              attrs: {
                "color": "info"
              }
            }, on), [_vm._v(" fas fa-calendar-times ")])];
          }
        }], null, true)
      }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('FileWillExpire')) + " " + _vm._s(_vm.$d(new Date((_vm$partsSerialNumber9 = _vm.partsSerialNumbers[(_item$part11 = item.part) === null || _item$part11 === void 0 ? void 0 : _item$part11._id]) === null || _vm$partsSerialNumber9 === void 0 ? void 0 : _vm$partsSerialNumber9.expiration), "long")) + " ")])]), _c('v-tooltip', {
        attrs: {
          "top": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function (_ref7) {
            var _vm$partsSerialNumber10, _vm$partsSerialNumber11, _item$part12;
            var on = _ref7.on;
            return [_c('v-btn', _vm._g({
              staticClass: "ml-3",
              attrs: {
                "disabled": !((_vm$partsSerialNumber10 = _vm.partsSerialNumbers[(_item$part12 = item.part) === null || _item$part12 === void 0 ? void 0 : _item$part12._id]) !== null && _vm$partsSerialNumber10 !== void 0 && (_vm$partsSerialNumber11 = _vm$partsSerialNumber10.serialNumber) !== null && _vm$partsSerialNumber11 !== void 0 && _vm$partsSerialNumber11.file),
                "height": "24px",
                "x-small": "",
                "color": "error"
              },
              on: {
                "click": function ($event) {
                  return _vm.openDialogDeleteSerialNumberFile(item.part);
                }
              }
            }, on), [_c('v-icon', {
              attrs: {
                "x-small": ""
              }
            }, [_vm._v(" fas fa-trash ")])], 1)];
          }
        }], null, true)
      }, [_c('span', [_vm._v(_vm._s(_vm.$t('DeleteFile')))])])], 1) : _vm._e()]), _c('td', {
        staticClass: "text-center"
      }, [!item.canceled ? _c('v-simple-checkbox', {
        attrs: {
          "ripple": false
        },
        model: {
          value: _vm.cancelPartSelected[dispatch.supplier][(_item$part13 = item.part) === null || _item$part13 === void 0 ? void 0 : _item$part13._id],
          callback: function ($$v) {
            var _item$part14;
            _vm.$set(_vm.cancelPartSelected[dispatch.supplier], (_item$part14 = item.part) === null || _item$part14 === void 0 ? void 0 : _item$part14._id, $$v);
          },
          expression: "cancelPartSelected[dispatch.supplier][item.part?._id]"
        }
      }) : _vm._e()], 1), _c('td', {
        staticClass: "text-right"
      }, [(_item$part15 = item.part) !== null && _item$part15 !== void 0 && _item$part15._id ? _c('a', {
        ref: `downloadFileButton${(_item$part16 = item.part) === null || _item$part16 === void 0 ? void 0 : _item$part16._id}`,
        refInFor: true,
        staticClass: "d-none",
        attrs: {
          "download": _vm.partsDownloadName[(_item$part17 = item.part) === null || _item$part17 === void 0 ? void 0 : _item$part17._id]
        }
      }) : _vm._e(), (_item$part18 = item.part) !== null && _item$part18 !== void 0 && _item$part18._id && _vm.downloadChoices[(_item$part19 = item.part) === null || _item$part19 === void 0 ? void 0 : _item$part19._id] ? _c('BtnMenu', {
        attrs: {
          "loading": _vm.partsLoading[(_item$part20 = item.part) === null || _item$part20 === void 0 ? void 0 : _item$part20._id],
          "disabled": _vm.partsLoading[(_item$part21 = item.part) === null || _item$part21 === void 0 ? void 0 : _item$part21._id] || _vm.partsSerialNumbers[(_item$part22 = item.part) === null || _item$part22 === void 0 ? void 0 : _item$part22._id] && ((_vm$partsSerialNumber12 = _vm.partsSerialNumbers[(_item$part23 = item.part) === null || _item$part23 === void 0 ? void 0 : _item$part23._id]) === null || _vm$partsSerialNumber12 === void 0 ? void 0 : (_vm$partsSerialNumber13 = _vm$partsSerialNumber12.serialNumber) === null || _vm$partsSerialNumber13 === void 0 ? void 0 : _vm$partsSerialNumber13.csgStatus) !== 'VALID',
          "color": "primary",
          "choices": _vm.downloadChoices[(_item$part24 = item.part) === null || _item$part24 === void 0 ? void 0 : _item$part24._id]
        },
        on: {
          "click": function (choice) {
            var _item$part25;
            _vm.getPartFile(dispatch.supplier, (_item$part25 = item.part) === null || _item$part25 === void 0 ? void 0 : _item$part25._id, choice);
          }
        }
      }, [_c('v-icon', {
        staticClass: "mr-2",
        attrs: {
          "small": ""
        }
      }, [_vm._v(" fa fa-download ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Download')) + " ")])], 1) : _vm._e()], 1)]);
    }), 0)]), dispatch.customLines.length ? _c('v-simple-table', {
      staticClass: "mt-4"
    }, [_c('thead', [_c('tr', [_c('th', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('Item')) + " ")])]), _c('th', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('Quantity')) + " ")])]), _c('th', {
      staticClass: "text-center"
    }, [_c('v-btn', {
      staticClass: "text-none",
      attrs: {
        "small": "",
        "color": "error",
        "disabled": !Object.values(_vm.cancelDispatchCustomLineSelected[dispatch.supplier]).some(function (b) {
          return !!b;
        })
      },
      on: {
        "click": function ($event) {
          return _vm.openDialogRemoveCustomLinesFromDispatch(dispatch.supplier);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-times-circle ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('CancelSelectedLines')) + " ")])], 1)], 1)])]), _c('tbody', _vm._l(dispatch.customLines, function (customLine, customLineIndex) {
      return _c('tr', {
        key: customLineIndex,
        class: {
          'canceled-row': customLine.canceled
        }
      }, [_c('td', [_c('span', [_vm._v(" " + _vm._s(customLine.description) + " ")]), customLine.canceled ? _c('span', {
        staticClass: "ml-2 text-h6 font-weight-bold error--text"
      }, [_vm._v(" [" + _vm._s(_vm.$t('Canceled').toUpperCase()) + "] ")]) : _vm._e()]), _c('td', [_c('span', [_vm._v(" " + _vm._s(customLine.quantity) + " ")]), customLine.canceled ? _c('span', {
        staticClass: "ml-2 text-h6 font-weight-bold error--text"
      }, [_vm._v(" -> 0 ")]) : _vm._e()]), _c('td', {
        staticClass: "text-center"
      }, [!customLine.canceled ? _c('v-simple-checkbox', {
        attrs: {
          "ripple": false
        },
        model: {
          value: _vm.cancelDispatchCustomLineSelected[dispatch.supplier][customLine === null || customLine === void 0 ? void 0 : customLine._id],
          callback: function ($$v) {
            _vm.$set(_vm.cancelDispatchCustomLineSelected[dispatch.supplier], customLine === null || customLine === void 0 ? void 0 : customLine._id, $$v);
          },
          expression: "cancelDispatchCustomLineSelected[dispatch.supplier][customLine?._id]"
        }
      }) : _vm._e()], 1)]);
    }), 0)]) : _vm._e(), _c('v-dialog', {
      attrs: {
        "max-width": "500"
      },
      model: {
        value: _vm.dialogRemoveDispatchCustomLine[dispatch.supplier],
        callback: function ($$v) {
          _vm.$set(_vm.dialogRemoveDispatchCustomLine, dispatch.supplier, $$v);
        },
        expression: "dialogRemoveDispatchCustomLine[dispatch.supplier]"
      }
    }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('RemovingCustomLines')) + " ")]), _c('v-card-text', [_c('v-alert', {
      attrs: {
        "type": "warning",
        "text": "",
        "prominent": "",
        "outlined": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('WarningRemoveCustomLinesIrreversible')) + " ")]), _c('v-btn', {
      staticClass: "text-none",
      attrs: {
        "width": "100%",
        "color": "info",
        "loading": _vm.loadingRemoveCustomLine
      },
      on: {
        "click": function ($event) {
          return _vm.removeCustomLinesFromOrderDispatch(dispatch.supplier);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('Validate')) + " ")])], 1), _c('v-card-actions', [_c('v-btn', {
      staticClass: "text-none",
      attrs: {
        "color": "info",
        "loading": _vm.loadingRemoveCustomLine
      },
      on: {
        "click": function ($event) {
          return _vm.closeDialogRemoveCustomLinesFromDispatch(dispatch.supplier);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-times-circle ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")])], 1)], 1)], 1)], 1), _c('v-dialog', {
      attrs: {
        "max-width": "500"
      },
      model: {
        value: _vm.dialogRemovePartsFromOrder[dispatch.supplier],
        callback: function ($$v) {
          _vm.$set(_vm.dialogRemovePartsFromOrder, dispatch.supplier, $$v);
        },
        expression: "dialogRemovePartsFromOrder[dispatch.supplier]"
      }
    }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('RemovingPartsFromThisOrder')) + " ")]), _c('v-card-text', [_c('v-alert', {
      attrs: {
        "type": "warning",
        "text": "",
        "prominent": "",
        "outlined": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('WarningRemovePartIrreversible')) + " ")]), _c('v-btn', {
      staticClass: "text-none",
      attrs: {
        "width": "100%",
        "color": "info",
        "loading": _vm.loadingRemovePartsFromOrder
      },
      on: {
        "click": function ($event) {
          return _vm.removePartsFromOrderDispatch(dispatch.supplier);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('Validate')) + " ")])], 1), _c('v-card-actions', [_c('v-btn', {
      staticClass: "text-none",
      attrs: {
        "color": "info",
        "loading": _vm.loadingRemovePartsFromOrder
      },
      on: {
        "click": function ($event) {
          return _vm.closeDialogRemovePartsFromOrder(dispatch.supplier);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" fas fa-times-circle ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")])], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1);
  }), _vm.order.quote.customLines.length > 0 ? _c('v-card', {}, [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-4",
    attrs: {
      "color": "info"
    }
  }, [_vm._v(" fas fa-cubes ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('OtherItems')) + " ")])], 1), _c('v-card-text', [_c('v-simple-table', [_c('thead', [_c('tr', [_c('th', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('Item')) + " ")])]), _c('th', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('Quantity')) + " ")])]), _c('th', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "small": "",
      "color": "error",
      "disabled": !Object.values(_vm.cancelOrderCustomLineSelected).some(function (b) {
        return !!b;
      })
    },
    on: {
      "click": _vm.openDialogRemoveCustomLinesFromOrder
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('CancelSelectedLines')) + " ")])], 1)], 1)])]), _c('tbody', _vm._l(_vm.order.quote.customLines, function (customLine, customLineIndex) {
    return _c('tr', {
      key: customLineIndex,
      class: {
        'canceled-row': customLine.canceled
      }
    }, [_c('td', [_c('span', [_vm._v(" " + _vm._s(customLine.description) + " ")]), customLine.canceled ? _c('span', {
      staticClass: "ml-2 text-h6 font-weight-bold error--text"
    }, [_vm._v(" [" + _vm._s(_vm.$t('Canceled').toUpperCase()) + "] ")]) : _vm._e()]), _c('td', [_c('span', [_vm._v(" " + _vm._s(customLine.quantity) + " ")]), customLine.canceled ? _c('span', {
      staticClass: "ml-2 text-h6 font-weight-bold error--text"
    }, [_vm._v(" -> 0 ")]) : _vm._e()]), _c('td', {
      staticClass: "text-center"
    }, [!customLine.canceled ? _c('v-simple-checkbox', {
      attrs: {
        "ripple": false
      },
      model: {
        value: _vm.cancelOrderCustomLineSelected[customLine === null || customLine === void 0 ? void 0 : customLine._id],
        callback: function ($$v) {
          _vm.$set(_vm.cancelOrderCustomLineSelected, customLine === null || customLine === void 0 ? void 0 : customLine._id, $$v);
        },
        expression: "cancelOrderCustomLineSelected[customLine?._id]"
      }
    }) : _vm._e()], 1)]);
  }), 0)])], 1)], 1) : _vm._e()], 2) : _vm._e(), _c('v-dialog', {
    attrs: {
      "max-width": "500"
    },
    model: {
      value: _vm.dialogRemoveOrderCustomLine,
      callback: function ($$v) {
        _vm.dialogRemoveOrderCustomLine = $$v;
      },
      expression: "dialogRemoveOrderCustomLine"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('RemovingCustomLines')) + " ")]), _c('v-card-text', [_c('v-alert', {
    attrs: {
      "type": "warning",
      "text": "",
      "prominent": "",
      "outlined": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('WarningRemoveCustomLinesIrreversible')) + " ")]), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "width": "100%",
      "color": "info",
      "loading": _vm.loadingRemoveCustomLine
    },
    on: {
      "click": _vm.removeCustomLinesFromOrder
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Validate')) + " ")])], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "loading": _vm.loadingRemoveCustomLine
    },
    on: {
      "click": _vm.closeDialogRemoveCustomLinesFromOrder
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    model: {
      value: _vm.dialogGenerateItemSerialNumberFile,
      callback: function ($$v) {
        _vm.dialogGenerateItemSerialNumberFile = $$v;
      },
      expression: "dialogGenerateItemSerialNumberFile"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('RestartItemSerialNumberFileGenerationTitle')) + " ")]), _c('v-divider'), _vm.currentPart ? _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('RestartItemSerialNumberFileGenerationText1', {
    partName: _vm.currentPart.name
  })) + " "), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(" " + _vm._s((_vm$partsSerialNumber14 = _vm.partsSerialNumbers[(_vm$currentPart = _vm.currentPart) === null || _vm$currentPart === void 0 ? void 0 : _vm$currentPart._id]) === null || _vm$partsSerialNumber14 === void 0 ? void 0 : (_vm$partsSerialNumber15 = _vm$partsSerialNumber14.serialNumber) === null || _vm$partsSerialNumber15 === void 0 ? void 0 : _vm$partsSerialNumber15.csgStatus) + ". ")])]), ((_vm$partsSerialNumber16 = _vm.partsSerialNumbers[(_vm$currentPart2 = _vm.currentPart) === null || _vm$currentPart2 === void 0 ? void 0 : _vm$currentPart2._id]) === null || _vm$partsSerialNumber16 === void 0 ? void 0 : (_vm$partsSerialNumber17 = _vm$partsSerialNumber16.serialNumber) === null || _vm$partsSerialNumber17 === void 0 ? void 0 : _vm$partsSerialNumber17.csgStatus) === 'VALID' || ((_vm$partsSerialNumber18 = _vm.partsSerialNumbers[(_vm$currentPart3 = _vm.currentPart) === null || _vm$currentPart3 === void 0 ? void 0 : _vm$currentPart3._id]) === null || _vm$partsSerialNumber18 === void 0 ? void 0 : (_vm$partsSerialNumber19 = _vm$partsSerialNumber18.serialNumber) === null || _vm$partsSerialNumber19 === void 0 ? void 0 : _vm$partsSerialNumber19.csgStatus) === 'IN_PROGRESS' ? _c('p', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('RestartProcessNotAdvised')) + " ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.$t('RestartItemSerialNumberFileGenerationText2')))]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('SpecifyExpiration')
    },
    model: {
      value: _vm.expirationDate,
      callback: function ($$v) {
        _vm.expirationDate = $$v;
      },
      expression: "expirationDate"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "disabled": !_vm.expirationDate,
      "number": "",
      "suffix": _vm.$t('Days')
    },
    model: {
      value: _vm.expirationDays,
      callback: function ($$v) {
        _vm.expirationDays = $$v;
      },
      expression: "expirationDays"
    }
  })], 1)], 1), _c('v-divider'), _vm.currentPart ? _c('SerialNumberCSGParametersSelector', {
    ref: "serialNumberCSGParametersSelectorRef",
    attrs: {
      "part": _vm.currentPart
    }
  }) : _vm._e()], 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.closeDialogItemSerialNumberFileGeneration
    }
  }, [_c('v-icon', {
    staticClass: "pr-2"
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loadingAPI,
      "color": "success"
    },
    on: {
      "click": _vm.restartItemSerialNumberFileGeneration
    }
  }, [_c('v-icon', {
    staticClass: "pr-2"
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Validate')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    model: {
      value: _vm.dialogGenerateOrderSerialNumberFiles,
      callback: function ($$v) {
        _vm.dialogGenerateOrderSerialNumberFiles = $$v;
      },
      expression: "dialogGenerateOrderSerialNumberFiles"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('RestartOrderSerialNumberFilesGenerationTitle')) + " ")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('RestartOrderSerialNumberFilesGenerationText1')) + " "), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s(_vm.orderCSGStatus) + ".")])]), _vm.orderCSGStatus === 'VALID' || _vm.orderCSGStatus === 'IN_PROGRESS' ? _c('p', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('RestartProcessNotAdvised')) + " ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.$t('RestartOrderSerialNumberFilesGenerationText2')))]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('SpecifyExpiration')
    },
    model: {
      value: _vm.expirationDate,
      callback: function ($$v) {
        _vm.expirationDate = $$v;
      },
      expression: "expirationDate"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "disabled": !_vm.expirationDate,
      "number": "",
      "suffix": _vm.$t('Days')
    },
    model: {
      value: _vm.expirationDays,
      callback: function ($$v) {
        _vm.expirationDays = $$v;
      },
      expression: "expirationDays"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.closeDialogOrderSerialNumberFilesGeneration
    }
  }, [_c('v-icon', {
    staticClass: "pr-2"
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loadingAPI,
      "color": "success"
    },
    on: {
      "click": _vm.restartOrderSerialNumberFilesGeneration
    }
  }, [_c('v-icon', {
    staticClass: "pr-2"
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Validate')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    model: {
      value: _vm.dialogDeleteSerialNumberFile,
      callback: function ($$v) {
        _vm.dialogDeleteSerialNumberFile = $$v;
      },
      expression: "dialogDeleteSerialNumberFile"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('DeleteFile')))]), _c('v-divider'), _vm.currentPart ? _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('DeleteSerialNumberFileText1', {
    partName: _vm.currentPart.name
  })) + " ")])]) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.closeDialogDeleteSerialNumberFile
    }
  }, [_c('v-icon', {
    staticClass: "pr-2"
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loadingAPI,
      "color": "success"
    },
    on: {
      "click": _vm.deleteSerialNumberFile
    }
  }, [_c('v-icon', {
    staticClass: "pr-2"
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Validate')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "450px"
    },
    model: {
      value: _vm.modalDeletion,
      callback: function ($$v) {
        _vm.modalDeletion = $$v;
      },
      expression: "modalDeletion"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('confirmDeletion')) + " ")]), _c('v-card-text', {
    staticClass: "pa-5 body-1"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('confirmDeletionText1')))]), _c('p', [_vm._v(_vm._s(_vm.$t('confirmDeletionText2')))])]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.modalDeletion = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "primary",
      "loading": _vm.deletionInProgress
    },
    on: {
      "click": _vm.deleteSupplierOrderInvoice
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Confirm')) + " ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }